import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { AuthProvider } from "./views/Components/Authentication";
import ProtectedRoutes from "./views/Components/ProtectedRoute";
import Inicio from "./views/Inicio";
import Bases from "./views/Bases";
import VistaLogin from "./views/Login";

import Arbolesregistrados from "./views/Arbolesregistrados";
import Cantidadcolegios from "./views/Cantidadcolegios";
import Cantidadalumnos from "./views/Cantidadalumnos";
import Acercade from "./views/Acercade";
import Videoexplicativo from "./views/Videoexplicativo";
import Visor from "./views/Visor";
import Dashboard from "./views/Administration/Dashboard";
import RegistrosArboles from "./views/Administration/RegistrosArboles";
import VistaGaleria from "./views/Galeria";
import VistaPolitica from "./views/PoliticaPrivacidad";
import Fichaarbol from "./views/Fichaarbol";
import Buscador from "./views/Buscador";
import VisorMaynas from "./views/Visormaynas";
import VisorPeru from "./views/Visorperu";
import VisorLima from "./views/Visorlima";
import Visorcuencananay from "./views/Visorcuencananay";
import Participantes from "./views/Participantes";
import Estadisticas from "./views/Estadisticas";
import Prueba from "./views/AlbumConcursante";
import Albumconcursante from "./views/AlbumConcursante";
import EditorAlbum from "./views/Administration/EditorAlbum";
import Album2 from "./views/Administration/Album2";
import Donaciones from "./views/Donaciones";

function App() {
  return (
    <AuthProvider>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<Inicio />} />
            <Route path="/bases" element={<Bases />} />
            <Route path="/login" element={<VistaLogin />} />
            <Route path="/arboles" element={<Arbolesregistrados />} />
            <Route path="/colegios" element={<Cantidadcolegios />} />
            <Route path="/participantes" element={<Participantes />} />
            <Route path="/acerca-de" element={<Acercade />} />
            <Route path="/estadisticas" element={<Estadisticas />} />
            <Route path ="/donaciones" element={<Donaciones />} />
            

            <Route path="/" element={<ProtectedRoutes />}>
              <Route path="/main-dashboard" element={<Dashboard />} />
              <Route path="/album-concursante" element={<Albumconcursante />} />
              <Route path='/albums/:regIntId' element={<Album2 />} />
              <Route
                path="/registros-arboles/:id"
                element={<RegistrosArboles />}
              />
              <Route path = "/editor-album/:regIntId" element={<EditorAlbum />} />
            </Route>

            <Route path="/galeria" element={<VistaGaleria />} />
            <Route path="/politica-de-privacidad" element={<VistaPolitica />} />
            <Route path="/visor" element={<Visor />} />
            <Route path="/visor-maynas" element={<VisorMaynas />} />
            <Route path="/visor-peru" element={<VisorPeru />} />
            <Route path="/visor-lima" element={<VisorLima />} />
            <Route path="/visor-cuenca-nanay" element={<Visorcuencananay />} />
            <Route path="/ficha-arbol/:id" element={<Fichaarbol />} />
            <Route path="/buscador-especie" element={<Buscador />} />
            <Route path="/video-explicativo" element={<Videoexplicativo />} />
            {/* <Route path="/estadisticas" element={<Estadisticas />} /> */}

            {/* Otras rutas públicas aquí */}
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
