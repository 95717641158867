import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import logo from './../Components/LOGO2.png';
import { useAuth } from '../Components/Authentication';
import './../Source/Dashboard.css';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'flowbite/dist/flowbite.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faBook, faTint, faMap } from '@fortawesome/free-solid-svg-icons';
import Main from './Main';
import MapVisitors from './MapVisitors';
import Ranking from './Ranking';
import Visitors from './Visitors';
import Album from './Album';

const apiUrl = process.env.REACT_APP_API_URL;
const debugMode = process.env.REACT_APP_DEBUG_MODE === 'true';

const DataTableComponent = () => {
    const [data, setData] = useState([]);

    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [showParticipantesTable, setShowParticipantesTable] = useState(false);
    const [showRankingTable, setShowRankingTable] = useState(false);
    const [showVisitantesTable, setShowVisitantesTable] = useState(false);
    const [showMapaTable, setShowMapaTable] = useState(false);
    const [showMainTable, setShowMainTable] = useState(false);
    const [showAlbumList, setShowAlbumList] = useState(false);

    const [VisitantesNames, setVisitantesNames] = useState(['Visitantes', 'Mapa visitantes', 'Errores y TryCatch']);
    const [ActividadesNames, setActividadesNames] = useState(['Participantes', 'Ranking']);

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    }

    const handleMenuItemClick = (item) => {
        if (item === "Participantes") {
            setShowParticipantesTable(true);
            setShowRankingTable(false);
            setShowVisitantesTable(false);
            setShowMapaTable(false);
            setShowMainTable(false);
            setShowAlbumList(false);
        } else if (item === "Ranking") {
            setShowParticipantesTable(false);
            setShowRankingTable(true);
            setShowVisitantesTable(false);
            setShowMapaTable(false);
            setShowMainTable(false);
            setShowAlbumList(false);
        } else if (item === "Album") {
            setShowParticipantesTable(false);
            setShowRankingTable(false);
            setShowVisitantesTable(false);
            setShowMapaTable(false);
            setShowMainTable(false);
            setShowAlbumList(true);
        }  
        
        else if (item === "Visitantes") {
            setShowParticipantesTable(false);
            setShowRankingTable(false);
            setShowVisitantesTable(true);
            setShowMapaTable(false);
            setShowMainTable(false);
            setShowAlbumList(false);
        } else if (item === "Mapa visitantes") {
            setShowParticipantesTable(false);
            setShowRankingTable(false);
            setShowVisitantesTable(false);
            setShowMapaTable(true);
            setShowMainTable(false);
            setShowAlbumList(false);
        } else {
            setShowParticipantesTable(false);
            setShowAlbumList(false);
            setShowRankingTable(false);
            setShowVisitantesTable(false);
            setShowMapaTable(false);
            setShowMainTable(true);
           
        }
    };

    useEffect(() => {
        axios.get(apiUrl+"participantes")
            .then(response => {
                const transformedData = response.data.users.map(user => ({
                    id: user.use_int_id,
                    nombre: user.use_txt_name,
                    apellido: user.use_txt_lastname,
                    fecha: user.use_txt_datecreation,
                    distrito: user.use_txt_district,
                    school: user.use_txt_school
                }));

                transformedData.sort((a, b) => {
                    const dateA = new Date(a.fecha);
                    const dateB = new Date(b.fecha);

                    return dateB - dateA;
                });

                setData(transformedData);
            })
            .catch(error => console.error("Error fetching data:", error));
    }, []);

    const columns = [
        { name: 'N°', selector: 'id', sortable: true },
        { name: 'Nombre', selector: 'nombre', sortable: true },
        { name: 'Apellido', selector: 'apellido', sortable: true },
        { name: 'Colegio', selector: 'school', sortable: true },
        { name: 'Fecha en la que se unió', selector: 'fecha', sortable: true },
        { name: 'Distrito', selector: 'distrito', sortable: true },
        {
            name: 'Opciones',
            cell: row => (
                <button onClick={() => window.open('/registros-arboles/' + row.id, '_blank')}>
                    Registros
                </button>
            )
        }
    ];

    const { logout } = useAuth();

    const handleLogout = () => {
        logout();
        window.location.href = '/login';
    }

    return (
        <>
            <div className="row">
                <div className="col col-2">
                    <button onClick={toggleSidebar}>☰</button>
                    <Sidebar collapsed={!sidebarVisible}>
                        <Menu>
                            <MenuItem > IDENTIARBOL</MenuItem>
                            <SubMenu label={<div><FontAwesomeIcon icon={faTachometerAlt} /> Actividades</div>}>
                                <SubMenu label="Concurso">
                                    {ActividadesNames.map(item => (
                                        <MenuItem key={item} onClick={() => handleMenuItemClick(item)}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </SubMenu>
                            </SubMenu>
                            <SubMenu label={<div><FontAwesomeIcon icon={faTachometerAlt} /> Bitácora</div>}>
                                <SubMenu label="Eventos sistema">
                                    {VisitantesNames.map(district => (
                                        <MenuItem key={district} onClick={() => handleMenuItemClick(district)}>
                                            {district}
                                        </MenuItem>
                                    ))}
                                </SubMenu>
                            </SubMenu>
                            <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
                        </Menu>
                    </Sidebar>
                </div>
                <div className="col">
                    {showParticipantesTable && (
                        <DataTable
                            title="Participantes"
                            columns={columns}
                            data={data}
                            
                            pagination
                        />
                    )}
                    {showRankingTable && (
                        <Ranking />
                    )}
                    {showVisitantesTable && (
                        <Visitors />
                    )}
                    {showMapaTable && (
                        <MapVisitors />
                    )}
                    {showMainTable && (
                        <Main />
                    )}
                    {showAlbumList && (
                        <Album />
                    )}
                </div>
            </div>
        </>
    );
};

export default DataTableComponent;
