import React from 'react';
import Header from './Components/Header';
import VisitorInfo from './Info';

const VistaPolitica = () => {
    return (
        <>
            <Header/>
            <VisitorInfo page="POLITICA DE PRIVACIDAD"></VisitorInfo>
            <div style={{ margin: '0 auto', maxWidth: '800px', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <h1 style={{ textAlign: 'center', color: '#4CAF50' }}>Política de Privacidad de Identiarbol</h1>
            <p style={{ textAlign: 'center', fontStyle: 'italic' }}>Fecha de última actualización: [Fecha]</p>
            
            <section style={{ marginBottom: '20px' }}>
                <h2 style={{ color: '#4CAF50' }}>1. Información que Recopilamos</h2>
                <p>Identiarbol recopila información sobre especies de árboles, que incluye, pero no se limita a, fotografías, ubicación, y otros datos relacionados.</p>
            </section>
            
            <section style={{ marginBottom: '20px' }}>
                <h2 style={{ color: '#4CAF50' }}>2. Cómo Usamos la Información</h2>
                <p>Utilizamos la información recopilada para estudiar y conservar las especies de árboles, mejorar nuestra aplicación y contribuir a la ciencia y educación ambiental.</p>
            </section>
            
            <section style={{ marginBottom: '20px' }}>
                <h2 style={{ color: '#4CAF50' }}>3. Compartir Información</h2>
                <p>No vendemos ni compartimos tu información personal con terceros para fines comerciales. Podemos compartir información anónima y agregada con entidades de investigación y conservación.</p>
            </section>
            
            <section style={{ marginBottom: '20px' }}>
                <h2 style={{ color: '#4CAF50' }}>4. Seguridad de la Información</h2>
                <p>Nos esforzamos por proteger tu información mediante medidas de seguridad técnicas y organizativas.</p>
            </section>
            
            <section style={{ marginBottom: '20px' }}>
                <h2 style={{ color: '#4CAF50' }}>5. Cambios a Esta Política</h2>
                <p>Podemos actualizar nuestra política de privacidad periódicamente. Te notificaremos de cualquier cambio importante.</p>
            </section>
            
            <section style={{ marginBottom: '20px' }}>
                <h2 style={{ color: '#4CAF50' }}>6. Contacto</h2>
                <p>Si tienes preguntas sobre esta política de privacidad y/o deseas desactivar tu cuenta, por favor contáctanos a: inventalo.pe@gmail.com</p>
            </section>
        </div>
        </>


    );
};

export default VistaPolitica;
