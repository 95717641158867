import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

const apiUrl = process.env.REACT_APP_API_URL;
const debugMode = process.env.REACT_APP_DEBUG_MODE === 'true';

const openImageInNewTab = (data) => {
    const regIntId = data.use_txt_dni;
    const newTab = window.open(`/albums/${regIntId}`, '_blank');

 };

const Ranking = () => {
    const tableRef = useRef(null);
    const [rankingData, setRankingData] = useState([]);

    useEffect(() => {
        // Fetch ranking data from the API
        fetch(apiUrl+'user/ranking')
            .then((response) => response.json())
            .then((data) => {
                // Add a "N°" property for sequential numbering
                const numberedData = data.ranking.map((item, index) => ({
                    ...item,
                    'N°': index + 1,
                }));
                setRankingData(numberedData);
            })
            .catch((error) => console.error('Error fetching ranking data:', error));
    }, []);

    useEffect(() => {
        if (rankingData.length > 0) {
            // Initialize the DataTable with the fetched data
            const dataTable = $(tableRef.current).DataTable({
                data: rankingData,
                columns: [
                    { data: 'N°', title: 'N°' }, // Display the numbering
                    { data: 'conteo', title: 'Rank' }, // Display the "Rank" property
                    { data: 'use_txt_name', title: 'Name' },
                    { data: 'use_txt_dni', title: 'DNI' },
                    { data: 'use_txt_school', title: 'School' },
                    {data: null, 
                     title: 'Ver Album',
                     render: function(data, type, row) {
                        return `<button class="ver-button" data-dni="${row.use_txt_dni}" > Ver </button>`;
                     }
                    },
                   
                    
                
                ],
                pageLength: 30,
            });

            $(tableRef.current).on('click', '.ver-button', function () {
                const dni = $(this).data('dni');
                openImageInNewTab({ use_txt_dni: dni });
            });

            return () => {
                // Destroy the DataTable when the component unmounts to prevent memory leaks
                dataTable.destroy();
            };
        }
    }, [rankingData]);

    return (
        <div>
            <h1>Vista de Ranking</h1>
            <table ref={tableRef} className="display" style={{ width: '100%' }} />
        </div>
    );
};

export default Ranking;
