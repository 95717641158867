import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Header from './Components/Header';
import VisitorInfo from './Info';

const apiUrl = process.env.REACT_APP_API_URL;
const debugMode = process.env.REACT_APP_DEBUG_MODE === 'true';


const Participantes = () => {
    const [data, setData] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState('');
    const columns = [
        { name: 'N°', cell: (row, index) => index + 1, sortable: false, width: '10px' },
        { name: 'DNI', selector: 'use_txt_dni', sortable: true, width: '150px' },
        { name: 'Colegio', selector: 'use_txt_school', sortable: true, width: '380px' },
        { name: 'Se unió en:', selector: 'use_txt_datecreation', sortable: true, width: '150px' },
    ];

    useEffect(() => {
        fetch(apiUrl+'user/userlist')
            .then((response) => response.json())
            .then((data) => {
                setData(data.users);
            })
            .catch((error) => console.error("Error fetching data:", error));
    }, []);

    const schoolOptions = [...new Set(data.map(item => item.use_txt_school))];
    // Ordenar las opciones alfabéticamente
    schoolOptions.sort();

    const handleSchoolFilterChange = (event) => {
        setSelectedSchool(event.target.value);
    };

    const filteredData = selectedSchool
        ? data.filter(item => item.use_txt_school === selectedSchool)
        : data;

    return (
        <div>
            <Header></Header>
            <VisitorInfo page="PARTICIPANTES"></VisitorInfo>
            <section className='p-2'>
                <h1 style={{fontSize: 25}}><b>Participantes- Concurso</b></h1>
                
                <div className='p-2 mt-3'>
                    <label htmlFor="schoolSelect"><b>Filtrar por Colegio:</b></label>
                    <select
                        id="schoolSelect"
                        value={selectedSchool}
                        onChange={handleSchoolFilterChange}
                    >
                        <option value="">Mostrar Todos</option>
                        {schoolOptions.map(school => (
                            <option key={school} value={school}>{school}</option>
                        ))}
                    </select>
                </div>
                <div className='p-2 mt-3'>
                    <p><b>Resultados encontrados:</b> {filteredData.length}</p>
                </div>

                <DataTable
                    columns={columns}
                    data={filteredData}
                    pagination
                />
            </section>
        </div>
    );
};

export default Participantes;
