import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Components/Header';
import Banner from './Components/Banner.jpg';
import axios from 'axios';
import VisitorInfo from './Info';


const apiUrl = process.env.REACT_APP_API_URL;
const debugMode = process.env.REACT_APP_DEBUG_MODE === 'true';

const Inicio = () => {
    const [data, setData] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get(apiUrl+'frontend');
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);

    if (!data) return <p>Loading...</p>;

    const handleBoxClick = (route) => {
        navigate(route);
    };

    const handleSearch = () => {
        navigate(`/buscador-especie?query=${searchTerm}`);
    }

    return (
<>
<VisitorInfo page="inicio"></VisitorInfo>
            <Header />
            <div>
                <img src={Banner} alt="Banner Descriptivo" style={{ width: '100%', height: 'auto' }} />
            </div>
            <div style={{ padding: '20px' }}>
                <div style={{ marginRight: '20%', marginLeft: '20%', marginBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <input
                        type="text"
                        placeholder="Buscar"
                        className='success'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{
                            width: '250%', // Aprovecha todo el espacio disponible dentro del div
                            padding: '10px',
                            borderRadius: '8px 0px 0px 8px', // Borde redondeado solo en la izquierda
                        }}
                    />
                    <button 
                        
                        onClick={handleSearch} 
                        className="btn btn-success"
                        style={{ 
                            padding: '10px 20px', // Añade espacio a la izquierda y derecha del texto del botón
                            borderRadius: '0px 8px 8px 0px', // Borde redondeado solo en la derecha
                        }}
                    >
                        Buscar
                    </button>
                </div>


                {/* Cajas */}
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            {[
                                                { label: "REGISTROS FOTOGRÁFICOS", value: data.images[0].cantidad, route: "/galeria" },
                                                { label: "Árboles registrados", value: data.trees[0].cantidad, route: "/arboles" },
                                                { label: "Cantidad de participantes", value: data.users[0].cantidad, route: "/participantes" },
                                                // { label: "Estadísticas", value:'-', route: "/estadisticas" },
                                                { label: "Cantidad de colegios", value: data.schools[0].cantidad, route: "/colegios" },
                                                
                                            ].map((item, index) => (
                                                <div
                                                key={index}
                                                style={{
                                                    width: '22%',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '10px',
                                                    padding: '10px',
                                                    textAlign: 'center',
                                                    cursor: index < 4 ? 'pointer' : 'default', // Desactiva el clic en los dos últimos elementos
                                                }}
                                                onClick={() => (index < 4 ? handleBoxClick(item.route) : null)}
                                                >
                                                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{item.label}</div>
                                                <div style={{ marginTop: '10px', fontSize: '14px' }}>{item.value}</div>
                                                </div>
                                            ))}
                                            </div>

            </div>
           
        </>
    );
};

export default Inicio;
