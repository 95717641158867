import DataTable from 'react-data-table-component';
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polygon } from 'react-leaflet';
import axios from 'axios';
import Header from './Components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import L, { Icon } from 'leaflet';
import Iconomap from './Components/img/marcadorrojo.png';
import geojsonData from './Components/img/lima.json';
import './Source/visor.css';

const center = {
  lat: -12.006297,  
  lng: -77.051033,
};

const treeIcon = new Icon({
  iconUrl: Iconomap,
  iconSize: [35, 41],
  iconAnchor: [12, 41],
});

const districtColors = [

    '#30C030',

  ];

const VisorLima = () => {
  const [trees, setTrees] = useState([]);
  const [search, setSearch] = useState('');
  const [coordinates, setCoordinates] = useState([]);

  const [selectedDepartment, setSelectedDepartment] = useState('TODO');
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState([]);


  const [departments, setDepartments] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);

  const [isDeptDisabled, setIsDeptDisabled] = useState(false);
const [isProvDisabled, setIsProvDisabled] = useState(false);


useEffect(() => {
  if (geojsonData && geojsonData.features) {
      const uniqueDepartments = Array.from(new Set(geojsonData.features.map(f => f.properties.NOMBDEP)));
      setDepartments(uniqueDepartments);
      setSelectedDepartment(uniqueDepartments[0]);  // Establecer el primer departamento por defecto
  }
}, []);

    useEffect(() => {
        if (selectedDepartment && selectedDepartment !== 'TODO') {
            const uniqueProvinces = Array.from(new Set(geojsonData.features.filter(f => f.properties.NOMBDEP === selectedDepartment).map(f => f.properties.NOMBPROV)));
            setProvinces(uniqueProvinces);
        } else {
            setProvinces([]);
        }
    }, [selectedDepartment]);

    useEffect(() => {
        if (selectedProvince) {
            const uniqueDistricts = Array.from(new Set(geojsonData.features.filter(f => f.properties.NOMBPROV === selectedProvince).map(f => f.properties.NOMBDIST)));
            setDistricts(uniqueDistricts);
        } else {
            setDistricts([]);
        }
    }, [selectedProvince]);


  useEffect(() => {
    if (geojsonData.features && Array.isArray(geojsonData.features) && geojsonData.features.length > 0) {
      const allPolygons = geojsonData.features.filter(feature => feature.geometry && feature.geometry.type === 'MultiPolygon')
          .map(feature => feature.geometry.coordinates);
      if (Array.isArray(allPolygons) && allPolygons.length > 0) {
          setCoordinates(allPolygons);
      } else {
          setCoordinates([]);
      }
    } else {
      setCoordinates([]);
    }
  }, []);

  const handleSelectDepartment = (dept) => {
    setSelectedDepartment(dept === 'TODO' ? null : dept);
    setSelectedProvince(null);
    setSelectedDistrict([]);
};

const handleSelectProvince = (prov) => {
    // Añadir esta línea
    setSelectedProvince(prov);
    setSelectedDistrict([]);
};

const handleSelectDistrict = (dist) => {
  setSelectedDistrict(prevSelected => {
      if (prevSelected.includes(dist)) {
          return prevSelected.filter(d => d !== dist);  // Si ya está seleccionado, lo deselecciona
      } else {
          return [...prevSelected, dist];  // Si no está seleccionado, lo añade a la selección
      }
  });
};


  useEffect(() => {
    const fetchTrees = async () => {
      try {
        const response = await axios.get("http://108.181.166.127/identiarbol/identiarbolbackend/public/api/trees");
        setTrees(response.data.trees);
      } catch (error) {
        console.error("Error fetching trees", error);
      }
    };
    fetchTrees();
  }, []);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const filteredTrees = search
    ? trees.filter(tree => 
        tree.reg_txt_scientificname?.toLowerCase().includes(search.toLowerCase()) ||
        tree.reg_txt_commonnames?.toLowerCase().includes(search.toLowerCase()) ||
        tree.reg_txt_identifier?.toLowerCase().includes(search.toLowerCase())
      )
    : trees;

  const columns = [
    {
      name: 'Nombre Científico',
      selector: 'reg_txt_scientificname',
      sortable: true,
    },
    {
      name: 'Nombre Común',
      selector: 'reg_txt_commonnames',
      sortable: true,
    },
    {
      name: 'Identificador',
      selector: 'reg_txt_identifier',
      sortable: true,
    },
    {
      name: 'Fecha de Creación',
      selector: 'created_at',
      sortable: true,
    },
    {
      name: 'Acción',
      sortable: false,
      cell: row => (
        <a href={`/ficha-arbol/${row.reg_int_id}`} target="_blank" rel="noopener noreferrer">
          <button
            className="btn btn-success"
            style={{ 
              padding: '10px 20px',
              borderRadius: '8px',
            }}
          >
            Ver Ficha
          </button>
        </a>
      ),
    },
  ];

  return (
    <>
      <Header />
      <div className="d-flex flex-column align-items-center mt-5">
        <div className="input-group mb-3" style={{ width: '500px' }}> 
          <input
            type="text"
            className="form-control"
            placeholder="Buscar por nombre científico, común o identificador"
            value={search}
            onChange={handleSearchChange}
          />
        </div>
        
        {search && filteredTrees.length === 0 && (
          <p className="mb-3">No se encontraron resultados para su búsqueda.</p>
        )}

        <MapContainer center={center} zoom={8} style={{ width: '100%', height: '650px' }}>
        <div className="control" style={{ display: 'flex', gap: '20px' }}>
    
    {/* Departamentos */}
    <div style={{ height: '500px', overflowY: 'auto' }}>
        {departments.map(dept => (
            <div key={dept}>
                <label>
                    <input
                        type="radio"
                        name="department"
                        value={dept}
                        onChange={() => handleSelectDepartment(dept)}
                        checked={selectedDepartment === dept}
                        disabled={isDeptDisabled}
                    />
                    {dept}
                </label>
            </div>
        ))}
    </div>
    
    {/* Provincias */}
    <div style={{ height: '500px', overflowY: 'auto' }}>
        {selectedDepartment && selectedDepartment !== 'TODO' && provinces.map(prov => (
            <div key={prov}>
                <label>
                    <input
                        type="radio"
                        name="province"
                        value={prov}
                        onChange={() => handleSelectProvince(prov)}
                        checked={selectedProvince === prov}
                        disabled={isProvDisabled}
                    />
                    {prov}
                </label>
            </div>
        ))}
    </div>
    
    {/* Distritos */}
    <div style={{ height: '500px', overflowY: 'auto' }}>
    {selectedProvince && districts.map(dist => (
        <div key={dist}>
            <label>
                <input
                    type="checkbox"
                    name="district"
                    value={dist}
                    onChange={() => handleSelectDistrict(dist)}
                    checked={selectedDistrict.includes(dist)}
                />
                {dist}
            </label>
        </div>
    ))}
</div>


</div>



          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution='&copy; <a href="https://www.esri.com/">Esri</a>'
          />

          {filteredTrees.map(tree => (
            <Marker
              key={tree.reg_int_id}
              position={[parseFloat(tree.reg_txt_lat), parseFloat(tree.reg_txt_long)]}
              icon={treeIcon}
            >
              <Popup>
                <div>
                  <h2>{tree.reg_txt_scientificname}</h2>
                  <p>Nombre Común: {tree.reg_txt_commonnames}</p>
                  <p>Identificador: {tree.reg_txt_identifier}</p>
                  <p>Fecha de Creación: {tree.created_at}</p>
                  <p>
                    <a 
                      href={`/ficha-arbol/${tree.reg_int_id}`}
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <button 
                        className="btn btn-success"
                        style={{ 
                          padding: '10px 20px',
                          borderRadius: '8px',
                        }}
                      >
                        Ver Ficha
                      </button>
                    </a>
                  </p>
                </div>
              </Popup>
            </Marker>
          ))}

        {Array.isArray(coordinates) && coordinates.map((multiPolygon, multiIndex) => 
            (
                (!selectedDepartment || geojsonData.features[multiIndex].properties.NOMBDEP === selectedDepartment) &&
                (!selectedProvince || geojsonData.features[multiIndex].properties.NOMBPROV === selectedProvince) &&
                (!selectedDistrict.length || selectedDistrict.includes(geojsonData.features[multiIndex].properties.NOMBDIST))

            ) &&
            Array.isArray(multiPolygon) && 
            multiPolygon.map((polygon, polyIndex) => 
                Array.isArray(polygon) && 
                <Polygon 
                    key={`${multiIndex}-${polyIndex}`} 
                    positions={polygon[0].map(([lng, lat]) => [lat, lng])}
                    color={districtColors[multiIndex % districtColors.length]}
                />
            )
        )}


        </MapContainer>

        <div className="mt-4" style={{ width: '100%' }}> 
          <DataTable
            title="Lista de Árboles"
            columns={columns}
            data={filteredTrees}
            pagination
            highlightOnHover
          />
        </div>
      </div>
    </>
  );
};

export default VisorLima;
