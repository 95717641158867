import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MapContainer, LayersControl, CircleMarker, Popup, Polygon, } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import 'leaflet/dist/leaflet.css';
import { Button,  Col, Container, Row, Modal } from 'react-bootstrap';
import 'flowbite/dist/flowbite.css';
import MapLegend from './MapLegend';
import './../Source/StyleLegend.css';


    const apiUrl = process.env.REACT_APP_API_URL;
    const baseURL = "http://108.181.166.127/identiarbol/identiarbolbackend/public";

const Album = () => {
    const [userData, setUserData] = useState({});
    const [searchDNI, setSearchDNI] = useState('');
    const [allImages, setAllImages] = useState([]);
    const [visibleImages, setVisibleImages] = useState([]);
    const [visibleCount, setVisibleCount] = useState(20);
    const [hoveredImage, setHoveredImage] = useState(null);
    const [coordinate, setCoordinate] = useState([]);
    const [search, setSearch] = useState([]);
    const [selectedDescriptionFilter, setSelectedDescriptionFilter] = useState('All');
    const [showAllPoints, setShowAllPoints] = useState(true);

    const center = {
        lat: -3.7572821,
        lng: -73.2710273,
      };

    const updateUserData = (data) => {
        if (data.users.length > 0) {
            setUserData(data.users[0]);
        }
    };

    const updateImagesData = (data) => {
        if (data.images) {
            setAllImages(data.images);
            setVisibleImages(data.images.slice(0, visibleCount));
        }
    };
    const updateCoordinatesData = (data) =>{
        if(data.coordinate){
            setCoordinate(data.coordinate);
        }
    } 
    const [selectedStyle, setSelectedStyle] = useState("Aubergine");

    const googleApiKey = "AIzaSyA68xOsLic_QKxD4EcnwZDrtv-iE09-95M";

    const mapStyles = {
        Aubergine: require("../Stylemaps/aubergine-map-style.json"),
        Dark: require("../Stylemaps/dark-map-style.json"),
        Retro: require("../Stylemaps/retro-map-style.json"),
        Night: require("../Stylemaps/night-map-style.json"),
        Estandar: require("../Stylemaps/standard-map-style.json"),
      };

    const fetchData = async () => {
        try {
            const userResponse = await axios.get(`${apiUrl}user/useralbum/${searchDNI}`);
            updateUserData(userResponse.data);
            console.log(userResponse.data.users)

            const imagesResponse = await axios.get(`${apiUrl}userimage/${searchDNI}`);
            updateImagesData(imagesResponse.data);

            const coordinatesResponse = await axios.get(`${apiUrl}treecoordinate/${searchDNI}?descriptionFilter=${selectedDescriptionFilter}`);
            updateCoordinatesData(coordinatesResponse.data);
            console.log(coordinatesResponse.data.coordinate)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [searchDNI, visibleCount]);

    const loadMoreImages = () => {
        setVisibleCount((prevCount) => prevCount + 20);
    };

    const handleMouseOver = (image) => {
        setHoveredImage(image);
    };

    const handleMouseOut = () => {
        setHoveredImage(null);
    };

    const openImageInNewTab = (image) => {
       const regIntId = image.reg_int_id;
       const newTab = window.open(`/editor-album/${regIntId}`, '_blank');

    };

    return (
        <div>
         <h1><strong>Vista de Álbum</strong></h1>
          <Container>
            <Row>
                <Col xs = {4}>
                <div>
                <label>DNI:</label>
                <br />
                <input
                    type="text"
                    value={searchDNI}
                    onChange={(e) => setSearchDNI(e.target.value)}
                />
                <br />
                <button onClick={fetchData}>Buscar</button>
            </div>

            <div style={{ background: '#f0f0f0', padding: '40px', borderRadius: '5px', margin: '10px 0' }}>
                <h2 style={{ color: '#333', fontSize: '24px', marginBottom: '10px' }}>Datos del Usuario</h2>
                <div style={{ marginBottom: '10px' }}>
                    <span style={{ display: 'inline-block', width: '100px', fontWeight: 'bold' }}>Nombres:</span>
                    <span>{userData.use_txt_name}</span>
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <span style={{ display: 'inline-block', width: '100px', fontWeight: 'bold' }}>Apellidos:</span>
                    <span>{userData.use_txt_lastname}</span>
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <span style={{ display: 'inline-block', width: '100px', fontWeight: 'bold' }}>DNI:</span>
                    <span>{userData.use_txt_dni}</span>
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <span style={{ display: 'inline-block', width: '100px', fontWeight: 'bold' }}>Colegio:</span>
                    <span>{userData.use_txt_school}</span>
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <span style={{ display: 'inline-block', width: '100px', fontWeight: 'bold' }}>Distrito:</span>
                    <span>{userData.use_txt_district}</span>
                </div>
            </div>
                </Col>
                <Col xs = {8}>
                    <div>
                    <div>
            <br />
              <h4><b>Ubicación de los árboles registradas:</b></h4>
              <div  className='map-legend-container'>
              <MapLegend />
              </div>

              <div style={{boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', position: 'relative'}}>
               
                  <MapContainer center={center} zoom={12} style={{ height: '350px', width: '100%' }}>
                    <LayersControl position="topright">
                      {Object.keys(mapStyles).map(style => (
                        <LayersControl.BaseLayer
                          key={style}
                          checked={style === selectedStyle}
                          name={style + ' Map'}
                        >
                          <ReactLeafletGoogleLayer apiKey={googleApiKey} type="roadmap" styles={mapStyles[style]} />
                        </LayersControl.BaseLayer>
                      ))}
                    </LayersControl>
                    {coordinate.map(coordinate =>(
                    (selectedDescriptionFilter === 'All'||coordinate.reg_txt_typetree === selectedDescriptionFilter )&&showAllPoints || coordinate.reg_txt_typetree === 'All' )&&(<CircleMarker
                        key={coordinate.use_txt_dni}
                        center={[parseFloat(coordinate.reg_txt_lat), parseFloat(coordinate.reg_txt_long)]}
                        radius={6}
                        fillColor={
                            coordinate.reg_txt_typetree === 'Palmera' ? 'green' :
                            coordinate.reg_txt_typetree === 'Frutal' ? 'orange' :
                            coordinate.reg_txt_typetree === 'Maderable' ? 'brown' :
                            coordinate.reg_txt_typetree === 'Medicinal' ? 'blue' :
                            coordinate.reg_txt_typetree === 'Ornamental' ? 'yellow' :
                            coordinate.reg_txt_typetree === null ? 'black' : 'black'
                        }
                        weight={0.4}
                        fillOpacity={5}

                    >
                        <Popup>
                            <div>
                                <h2><b>Nombre Común: </b>{coordinate.reg_txt_commonnames}</h2>
                                <p><b>Nombre Científico: </b>{coordinate.reg_txt_scientificname}</p>
                                <ul>
                                    <li><p><b>Longitud: </b>{coordinate.reg_txt_long}</p></li>
                                    <li><p><b>Latitud: </b>{coordinate.reg_txt_lat}</p></li>
                                </ul>
                            </div>
                        </Popup>


                    </CircleMarker>))}
                  </MapContainer>
                  
                
              </div>
              {/* Menú desplegable y casilla de verificación */}
              <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1000 }}>
                                        <div style={{ marginBottom: '10px' }}>
                                            <label>Filtrar por descripción:</label>
                                            <select
                                                value={selectedDescriptionFilter}
                                                onChange={(e) => setSelectedDescriptionFilter(e.target.value)}
                                            >
                                                <option value="All">Todos</option>
                                                <option value="Ornamental">Ornamental</option>
                                                <option value="Maderable">Maderable</option>
                                                <option value="Medicinal">Medicinal</option>
                                                <option value="Frutal">Frutal</option>
                                                <option value="Palmera">Palmera</option>
                                                <option value={null}>Sin Asignar</option>
                                                
                                            </select>
                                        </div>
                                        
                                    </div>
            </div>
                    </div>
                </Col>
            </Row>
            
          </Container>

            <div>
                <div className="image-section">
                    <h2>Imágenes</h2>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {visibleImages.map((image, index) => (
                            <div
                                key={index}
                                className="image-item"
                                style={{ margin: '5px', position: 'relative', cursor: 'pointer' }}
                                onMouseOver={() => handleMouseOver(image)}
                                onMouseOut={handleMouseOut}
                                onClick={() => openImageInNewTab(image)}
                            >
                                <img
                                    src={`${baseURL}${image.ima_txt_urlimages}`}
                                    alt={`Imagen ${index + 1}`}
                                    style={{ width: '250px', height: '400px' }}
                                    loading="lazy"
                                    onLoad={(e) => {
                                        e.target.src = e.target.src; // Force re-render for lazy loading
                                    }}
                                />
                                {hoveredImage === image && (
                                    <div style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        background: 'rgba(0,0,0,0.5)',
                                        color: 'white',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        transition: 'opacity 0.5s, transform 1.0s',
                                        transform: hoveredImage === image ? 'translateY(0)' : 'translateY(100%)',
                                        opacity: hoveredImage === image ? 1 : 0,
                                    }}>
                                        <p>Parte: Completo</p>
                                    </div>
                                )}
                                <p>Nombre: {image.reg_txt_commonnames.toUpperCase()}</p>
                            </div>
                        ))}
                    </div>
                    {visibleCount < allImages.length && (
                        <div style={{ textAlign: 'center', margin: '20px' }}>
                            <button onClick={loadMoreImages}>Ver mas</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};



export default Album;


