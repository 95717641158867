import React, { useEffect, useState } from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const debugMode = process.env.REACT_APP_DEBUG_MODE === 'true';

const VisitorInfo = ({page}) => {
  const [ip, setIp] = useState(null);
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [browser, setBrowser] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    if (!dataFetched) {
      // Obtener la dirección IP del usuario
      axios.get('https://api.ipify.org?format=json')
        .then(response => {
          const ip = response.data.ip;
          setIp(ip);

          // Obtener información del país basada en la dirección IP utilizando ip-api.com
          axios.get(`http://ip-api.com/json/${ip}`)
            .then(response => {
              const data = response.data;
              setCountry(data.country);
              setCity(data.city);

              // Capturar información del navegador y hora del cliente
              setBrowser(navigator.userAgent);

              // Marcar que los datos se han obtenido y enviarlos a la API
              const now = new Date();
              const year = now.getFullYear();
              const month = String(now.getMonth() + 1).padStart(2, '0'); // Agrega un 0 inicial si es necesario
              const day = String(now.getDate()).padStart(2, '0');
              const hours = String(now.getHours()).padStart(2, '0');
              const minutes = String(now.getMinutes()).padStart(2, '0');
              const seconds = String(now.getSeconds()).padStart(2, '0');

              const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

              setDataFetched(true);
              const postData = {
                ser_int_id: 1,
                bin_txt_ip: ip,
                bin_txt_country: data.country,
                bin_txt_countrycode: data.countryCode,
                bin_txt_region: data.region,
                bin_txt_city: data.city,
                bin_txt_lat: data.lat,
                bin_txt_lon: data.lon,
                bin_txt_timezone: data.timezone,
                bin_txt_isp: data.isp,
                bin_txt_org: data.org,
                bin_txt_status: data.status,
                bin_txt_regionname: data.regionName,
                bin_txt_datecreation: formattedDateTime,
                bin_txt_page:page
              };
        
              // Enviar la solicitud POST a la API
              axios.post(apiUrl+'user/registerbin', postData)
                .then(response => {
                  console.log('Datos enviados con éxito a la API', response);
                })
                .catch(error => {
                  console.error('Error al enviar datos a la API', error);
                });
            })
            .catch(error => {
              console.error('Error al obtener información de país y ciudad', error);
            });
        })
        .catch(error => {
          console.error('Error al obtener la dirección IP', error);
        });

      // Configurar la actualización de la hora del cliente
      const intervalId = setInterval(() => {
        setCurrentTime(new Date().toLocaleTimeString());
      }, 1000);

      return () => {
        // Limpiar el intervalo cuando el componente se desmonta
        clearInterval(intervalId);
      };
    }
  }, [dataFetched, page]);



  return <></>;
};

export default VisitorInfo;
