import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Logo from './Components/LOGO2.png';
import Header from './Components/Header';
import VisitorInfo from './Info';

const apiUrl = process.env.REACT_APP_API_URL;
const apiUrlImg = process.env.REACT_API_URL_IMG;
const debugMode = process.env.REACT_APP_DEBUG_MODE === 'true';

const VistaGaleria = () => {
    const [allImages, setAllImages] = useState([]);
    const [visibleImages, setVisibleImages] = useState([]);
    const [visibleCount, setVisibleCount] = useState(50);
    const baseURL = 'https://identiarbol.org/servicesbackend/public/';
    const [hoveredImage, setHoveredImage] = useState(null);
    const hoverTimeout = useRef(null);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get(apiUrl+`images`);
                setAllImages(response.data.images);
            } catch (error) {
                console.error("Error fetching images", error);
            }
        };

        fetchImages();
    }, []);

    useEffect(() => {
        setVisibleImages(allImages.slice(0, visibleCount));
    }, [allImages, visibleCount]);

    const loadMoreImages = () => {
        setVisibleCount(prevCount => prevCount + 50);
    };

    const handleMouseOver = (image) => {
        clearTimeout(hoverTimeout.current);
        setHoveredImage(image);
    };

    const handleMouseOut = () => {
        hoverTimeout.current = setTimeout(() => {
            setHoveredImage(null);
        }, 1000); // Retraso de 300ms antes de desaparecer la información
    };

    return (
        <div>
            <Header />
            <VisitorInfo page="GALERIA"></VisitorInfo>
            <h1 style={{ textAlign: 'center' }}>Galería</h1>
            <p style={{ textAlign: 'center' }}>Total de imágenes: {allImages.length}</p>
            <div style={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                justifyContent: 'center' // Esto centra los elementos hijos horizontalmente 
            }}>
                {visibleImages.map((image) => (
                    <div key={image.ima_int_id} 
                         style={{ margin: '10px', position: 'relative' }}
                         onMouseOver={() => handleMouseOver(image)}
                         onMouseOut={handleMouseOut}>
                        <img 
                            src={Logo}
                            data-src={`${baseURL}${image.ima_txt_urlimages}`}
                            alt={image.ima_txt_part}
                            style={{ width: '250px', height: '300px' }}
                            loading="lazy"
                            onLoad={(e) => {
                                e.target.src = e.target.dataset.src;
                            }}
                        />
                        {hoveredImage === image && (
                            <div style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                background: 'rgba(0,0,0,0.5)',
                                color: 'white',
                                padding: '10px',
                                borderRadius: '5px',
                                transition: 'opacity 0.5s, transform 1.0s',
                                transform: hoveredImage === image ? 'translateY(0)' : 'translateY(100%)',
                                opacity: hoveredImage === image ? 1 : 0,
                            }}>
                                <p>Nombre Común: {image.reg_txt_commonnames}</p>
                                <p>Parte: {image.ima_txt_part}</p>
                                <p>Fecha de Creación: {image.ima_txt_datecreate}</p>
                                <a 
                                    href={`/ficha-arbol/${image.reg_int_id}`}
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    
                                >
                                    <button className='btn btn-success'>
                                        Ver Ficha
                                    </button>
                                </a>
                               
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {visibleCount < allImages.length && (
                <div style={{ textAlign: 'center', margin: '20px' }}>
                    <button onClick={loadMoreImages}>Cargar 50 más</button>
                </div>
            )}
        </div>
    );
};

export default VistaGaleria;