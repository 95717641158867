import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Container, Row, Modal } from "react-bootstrap";
import {
  MapContainer,
  TileLayer,
  Marker,
  CircleMarker,
  Popup,
  Polygon,
  LayersControl,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import Header from "./Components/Header";
import IconoMapa from "./Components/img/marcadorrojo.png"; // Asegúrate de poner la ruta correcta a tu imagen
import "leaflet/dist/leaflet.css";
import "flowbite/dist/flowbite.css";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

const apiUrl = process.env.REACT_APP_API_URL;
const apiUrlImg = "https://identiarbol.org/servicesbackend/public/";
const debugMode = process.env.REACT_APP_DEBUG_MODE === "true";

const Fichaarbol = () => {
  const googleApiKey = "AIzaSyBsbNYxFnF-urfInt4-C0isJ9B5p3MOkkw";
  const mapStyles = {
    Aubergine: require("./Stylemaps/aubergine-map-style.json"),
    Dark: require("./Stylemaps/dark-map-style.json"),
    Retro: require("./Stylemaps/retro-map-style.json"),
    Night: require("./Stylemaps/night-map-style.json"),
    Estandar: require("./Stylemaps/standard-map-style.json"),
  };

  const [selectedStyle, setSelectedStyle] = useState("Aubergine");

  const { id } = useParams();
  const [treeData, setTreeData] = useState(null);
  const [images, setImages] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (image) => {
    setSelectedImage(image);
    setShow(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl + `infotree/${id}`);
        const result = await response.json();

        setTreeData(result.tree[0]);
        setImages(result.images);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);

  if (!treeData || images.length === 0) return <p>Loading...</p>;

  const mainImage =
    images.find((image) => image.ima_txt_part === "Completo") || images[0];

  const icon = new L.Icon({
    iconUrl: IconoMapa,
    iconSize: [35, 32], // Tamaño del icono, puedes ajustarlo según tus necesidades
  });

  return (
    <>
      <Header />
      <Container className="mt-5 mb-5">
        <div className="text-center">
          <h1>Registro Electrónico de Arboles</h1>
        </div>
        <Row className="justify-content-center mt-4">
          <Col md={5}>
            <h4>
              <b>Datos básicos:</b>
            </h4>
            <div className="p-3 border rounded bg-light">
              <p>
                <strong>Código de árbol:</strong> {treeData.reg_txt_identifier}
              </p>
              <p>
                <strong>Nombre Científico:</strong>{" "}
                {treeData.reg_txt_scientificname}
              </p>
              <p>
                <strong>Nombres Comunes:</strong> {treeData.reg_txt_commonnames}
              </p>
              <p>
                <strong>Código autor:</strong> {treeData.use_int_id}
              </p>
              <p>
                <strong>Fecha de Creación:</strong>{" "}
                {treeData.reg_txt_datecreation}
              </p>
            </div>
            <h4>
              <b>Datos de ubicación:</b>
            </h4>
            <div className="p-3 border rounded bg-light">
              <MapContainer
                center={[treeData.reg_txt_lat, treeData.reg_txt_long]}
                zoom={13}
                style={{ height: "300px", width: "100%" }}
              >
                <LayersControl position="topright">
                  {Object.keys(mapStyles).map((style) => (
                    <LayersControl.BaseLayer
                      key={style}
                      checked={style === selectedStyle}
                      name={style + " Map"}
                    >
                      <ReactLeafletGoogleLayer
                        apiKey={googleApiKey}
                        type="roadmap"
                        styles={mapStyles[style]}
                      />
                    </LayersControl.BaseLayer>
                  ))}
                </LayersControl>
                <CircleMarker
                  center={[treeData.reg_txt_lat, treeData.reg_txt_long]}
                  /* position={[treeData.reg_txt_lat, treeData.reg_txt_long]} */
                  radius={5} // Puedes ajustar el tamaño del círculo según tus preferencias
                  //color="red" // Puedes ajustar el color del círculo según tus preferencias
                  fillColor="red" // Establece el color de fondo del círculo
                  color="yellow" // Establece el color del contorno del círculo
                  weight={0.4} // Ajusta el grosor del contorno
                  fillOpacity={5}
                ></CircleMarker>
              </MapContainer>
            </div>
          </Col>
          <Col md={5}>
            <img
              src={apiUrlImg + `${mainImage.ima_txt_urlimages}`}
              alt="Imagen principal"
              className="img-fluid rounded"
            />
          </Col>
        </Row>
        <div className="mt-5">
          <h2 className="text-center">
            Fotos de {treeData.reg_txt_commonnames}
          </h2>
          <Row>
            {images.map((image, index) => (
              <Col key={index} md={3} className="mt-3">
                <Card onClick={() => handleShow(image)}>
                  <Card.Img
                    variant="top"
                    src={apiUrlImg + `${image.ima_txt_urlimages}`}
                    className="img-fluid"
                    style={{
                      maxHeight: "150px",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                  />
                  <Card.Body>
                    <Card.Title>{image.ima_txt_part}</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {selectedImage && (
              <img
                src={apiUrlImg + `${selectedImage.ima_txt_urlimages}`}
                style={{
                  maxWidth: "300px",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
                alt="Seleccionada"
                className="img-fluid"
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Fichaarbol;
