import React from 'react';

import './../Source/StyleLegend.css';

const MapLegend = () =>{

return (
  <div className="map-legend">
    <h3 style={{ fontSize: '12px' }}>Tipo de Arboles:</h3>
    <div className="legend-item">
      <div className="legend-color" style={{ backgroundColor: 'green' }}></div>
      Palmera
    </div>
    <div className="legend-item">
      <div className="legend-color" style={{ backgroundColor: 'orange' }}></div>
      Frutal
    </div>
    <div className="legend-item">
      <div className="legend-color" style={{ backgroundColor: 'brown' }}></div>
      Maderable
    </div>
    <div className="legend-item">
      <div className="legend-color" style={{ backgroundColor: 'blue' }}></div>
      Medicinal
    </div>

    <div className="legend-item">
      <div className="legend-color" style={{ backgroundColor: 'yellow' }}></div>
      Ornamental
    </div>

    <div className="legend-item">
      <div className="legend-color" style={{ backgroundColor: '#a18262' }}></div>
      Palmera - Frutal
    </div>

    <div className="legend-item">
      <div className="legend-color" style={{ backgroundColor: 'black' }}></div>
      Sin Asignar
    </div>
  </div>

);
}

export default MapLegend;